.background {
  width: 100vw;
  height: 600px;
  background-color: rgb(255, 133, 124);
  margin-top: 7%;
}

@keyframes wipe-enter {
  0% {
    transform: scale(0, 0.025);
  }
  50% {
    transform: scale(1, 0.025);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .background-animation {
    animation: wipe-enter 1.5s 1;
  }
}

.title{
    font-size: 80px;
    color: white;
    font-weight: 200;
    text-align: right;
    margin-right: 5%;
    margin-left: 5%;
    padding-top: 4%;
    width: fit-content;
    float: right;
}

.about-me{
    font-size: 20px;
    color: white;
    font-weight: 400;
    text-align: right;
    padding-top: 10px;
    width: 70%;
    float: right;
    margin-right: 5%;
}

.rocket{
  padding-top: 100px;
  margin-left: 7%;
  width:13vw;
  animation: animate-rocket 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


@keyframes animate-rocket {
  0% {
    transform:  translateX(0px) translateY(10px);
  }
  5%{
    transform: translateY(0px);
  }
  10%{
    transform:  rotate(-5deg)translateX(-20px) translateY(-20px);
  }
  15%{
    transform:  rotate(-7.5deg)translateX(-30px) translateY(-30px);
  }
  20%{
    transform:  rotate(-10deg)translateX(-40px) translateY(-40px);
  }
  25%{
    transform:  rotate(-12.5deg)translateX(-50px) translateY(-50px);
  }
  30%{
    transform:  rotate(-15deg)translateX(-65px) translateY(-65px);
  }
  35%{
    transform:  rotate(-17.5deg)translateX(-80px) translateY(-80px);
  }
  40%{
    transform:  rotate(-17.5deg)translateX(-100px) translateY(-100px);
  }
  45%{
    transform:  rotate(-20deg)translateX(-150px) translateY(-150px);
  }
  50% {
    transform:  rotate(-25deg)translateX(-250px) translateY(-250px);
 }
 55%{
  transform:  rotate(-20deg)translateX(-150px) translateY(-150px);
 }
 60%{
  transform:  rotate(-17.5deg)translateX(-100px) translateY(-100px);
 }
 65%{
  transform:  rotate(-17.5deg)translateX(-80px) translateY(-80px);
 }
 70%{
  transform:  rotate(-15deg)translateX(-65px) translateY(-65px);
 }
 75%{
  transform:  rotate(-12.5deg)translateX(-50px) translateY(-50px);
 }
 80%{
  transform:  rotate(-10deg)translateX(-40px) translateY(-40px);
}
85%{
  transform:  rotate(-7.5deg)translateX(-30px) translateY(-30px);
}
90%{
  transform:  rotate(-5deg)translateX(-20px) translateY(-20px);
}
95%{
  transform: translateY(0px);
}
100%{
  transform:  translateX(0px) translateY(10px);
}
}
a{
  text-decoration: none;
  color: rgb(255, 224, 130);
  font-weight: 500;
}

a:hover{
  color: rgb(255, 191, 0);
}
.about-me-no{
  display: none;
}
.background-media {
  display: none;
}
@media screen and (max-width:769px){
  .background-media {
    width: 100vw;
    height: 65vw;
    background-color: rgb(255, 133, 124);
    display: block;
    top: calc(200px + 100vw);
    position: relative;
  }
  .background-animation{
    display: none;
  }
  .about-me{
    font-size: 2.5vw;
    color: white;
    font-weight: 400;
    text-align: right;
    padding-top: 10px;
    width: 70%;
    float: right;
    margin-right: 5%;
}
.title{
  font-size: 10vw;
  color: white;
  font-weight: 200;
  text-align: right;
  margin-right: 5%;
  margin-left: 5%;
  padding-top: 4%;
  width: fit-content;
  float: right;
}
}

