.backgroundProject {
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: block;
  }


.arrows2 {
    width: 60px;
    height: 72px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: -220vh;
  }
  
  .arrows2 path {
    stroke: rgb(255, 204, 0);
    fill: transparent;
    stroke-width: 1px;
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite;
  }
  @media (prefers-reduced-motion: no-preference) {
    .background-animation {
      animation: wipe-enter 1.5s 1;
    }
  }
  @keyframes arrow {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  @-webkit-keyframes arrow /*Safari and Chrome*/ {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  .arrows2 path.a1 {
    animation-delay: -1s;
    -webkit-animation-delay: -1s; /* Safari 和 Chrome */
  }
  
  .arrows2 path.a2 {
    animation-delay: -0.5s;
    -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
  }
  
  .arrows2 path.a3 {
    animation-delay: 0s;
    -webkit-animation-delay: 0s; /* Safari 和 Chrome */
  }

.title-projects{
    font-size: 80px;
    color: teal;
    font-weight: 200;
    text-align: center;
    padding-top: 3%;
    width: 100%;
}

.project-card{
    border: 1px solid rgb(1, 103, 103);
    border-radius: 15px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    background-color: rgb(1, 108, 108);
    padding: 7px;
    width: 28vw;
    height: 55vh;
    margin: 25px;
    margin-top: 30px;
    }

.projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    }
.backgroundProject-media{
  display: none;
}
@media screen{
  p{
    color: white;
    font-size: 18px;
    padding-top: 1vw;
    text-align: center;
  }
  h3{
    font-size: 25px;
  }
  .badge {
    background-color: rgb(248, 167, 91);
    color: white;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 800;
    border-radius: 20px;
  }
  #card-link {
    font-size: medium;
    justify-content: center;
    color: rgb(248, 167, 91);
  }
}

  @media screen and (max-width:769px){
    .background-animation{
      display: none;
    }
    .backgroundProject-media{
      width: 100vw;
      height: 80vw;
      background-color: white;
      display: block;
      position: relative;
      top: calc(200px + 100vw);
    }
    .title-projects{
      font-size: 10vw;
    }
    h3{
      font-size: 20px;
    }
    p{
      color: white;
      font-size: 15px;
      padding-top: 1vw;
      text-align: center;
    }
    #card-link {
      font-size: 15px;
      justify-content: center;
      color: rgb(248, 167, 91);
    }
    .project-card{
      border: 1px solid rgb(1, 103, 103);
      border-radius: 15px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      background-color: rgb(1, 108, 108);
      width: 28vw;
      height: 55vw;
      margin: 15px;
      margin-top: 30px;
      }
      .backgroundProject{
        display: none;
      }
      .badge{
        font-size: 13px;
      }
  }
  @media screen and (max-width:575px){
    .background-animation{
      display: none;
    }
    .backgroundProject-media{
      width: 100vw;
      height: 65vh;
      background-color: white;
      display: block;
      position: relative;
      top: calc(200px + 100vw);
    }
    .title-projects{
      font-size: 10vw;
    }
    h3{
      font-size: 15px;
    }
    p{
      color: white;
      font-size: 10px;
      padding-top: 1vw;
      text-align: center;
    }
    #card-link {
      font-size: 10px;
      justify-content: center;
      color: rgb(248, 167, 91);
    }
    .project-card{
      border: 1px solid rgb(1, 103, 103);
      border-radius: 15px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      background-color: rgb(1, 108, 108);
      width: 28vw;
      height: calc(200px + 20vh);
      margin: 15px;
      margin-top: 30px;
      }
      .backgroundProject{
        display: none;
      }
      .badge {
        display: none;
      }
  }
h3 {
  color: rgb(246, 143, 143);
  text-align: center;
}

#card-link:hover{
  color: rgb(237, 134, 37);
}
.badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

